<template>
  <!-- 头条新增模板 -->
  <div class="paddbox toutiaoAddModel">
    <div class="toutiaoAddModelTitle">
      <el-card>
        <div class="toutiaoAddModelTitleTop flex">
          <div>
            <selectSonAccount
              :data="selectSonAccountData"
              @getSelectSonAccountData="getSelectSonAccountData"
            ></selectSonAccount>
          </div>
          <div>
            <el-button type="danger" round @click="ResetPageFun()">重置</el-button>
          </div>
        </div></el-card
      >
    </div>
    <div class="toutiaoAddModelcontent">
      <el-card>
        <div class="toutiaoAddModel-cardbox ">
          <el-row :gutter="20">
            <!-- 广告组配置 -->
            <el-col :span="6"
              ><div class="grid-content ep-bg-purple" />
              <!-- 广告组配置模块开始 -->
              <div class="toutiaoAddModel-carditem">
                <div class="defineHead">
                  <span>广告组配置 </span>
                  <span @click="adsSetFun"
                    ><i title="编辑" class="cursor editIcon el-icon-edit disabled"></i
                  ></span>
                </div>
                <div class="defineBody">
                  <div class="mandatory">
                    <div class="settingBox">
                      <span class="s_label">广告组名称:</span>
                      <span class="s_text">{{ campaignDto.campaign_name }}</span>
                    </div>
                    <div class="settingBox">
                      <span class="s_label">广告类型:</span>
                      <span class="s_text">{{
                        campaignDto.campaign_type == 'disabled' ? '搜索广告' : '所有广告'
                      }}</span>
                    </div>
                    <div class="settingBox">
                      <span class="s_label">预算:</span>
                      <span class="s_text">{{
                        campaignDto.budget_mode == 'BUDGET_MODE_INFINITE'
                          ? '不限'
                          : campaignDto.budget
                      }}</span>
                    </div>
                    <div class="settingBox">
                      <span class="s_label">开启状态:</span>
                      <span class="s_text">{{
                        campaignDto.operation == 'enable' ? '开启' : '关闭'
                      }}</span>
                    </div>
                  </div>
                </div>
              </div></el-col
            >
            <!-- 广告计划配置 -->
            <el-col :span="6"
              ><div class="grid-content ep-bg-purple" />
              <!-- 广告计划配置模块开始 -->
              <div class="toutiaoAddModel-carditem">
                <div class="defineHead">
                  <span>广告计划配置 </span>
                  <span @click="AdPlanFun"
                    ><i title="编辑" class="cursor editIcon el-icon-edit disabled"></i
                  ></span>
                </div>
                <div class="defineBody">
                  <div class="mandatory">
                    <div class="settingBox">
                      <span class="s_label">计划名称:</span>
                      <span class="s_text">{{ adDto.name }}</span>
                    </div>
                    <div class="settingBox">
                      <span class="s_label">投放模式:</span>

                      <span class="s_text">{{ adDto.ms == 1 ? '电商' : '短剧' }}</span>
                    </div>
                    <div class="settingBox">
                      <span class="s_label">数据对接方式:</span>

                      <span class="s_text">{{ adDto.sjflag == 1 ? '事件管理' : '转化追踪' }}</span>
                    </div>
                    <div class="settingBox">
                      <span class="s_label">付费方式:</span>
                      <span class="s_text">按展示付费（OCPM）</span>
                    </div>
                    <div class="settingBox">
                      <span class="s_label">出价:</span>
                      <span class="s_text">{{ adDto.cpa_bid }}</span>
                    </div>
                    <div class="settingBox">
                      <span class="s_label">广告位置:</span>
                      <span class="s_text">首选媒体</span>
                    </div>
                    <div class="settingBox">
                      <span class="s_label">投放场景:</span>
                      <span class="s_text">{{
                        adDto.smart_bid_type == 'SMART_BID_CUSTOM'
                          ? '常规投放'
                          : adDto.smart_bid_type == 'SMART_BID_CONSERVATIVE'
                          ? '放量投放'
                          : adDto.smart_bid_type == 'SMART_BID_NO_BID'
                          ? '最大转化投放'
                          : ''
                      }}</span>
                    </div>
                    <div class="settingBox">
                      <span class="s_label">竞价策略:</span>
                      <span class="s_text">{{
                        adDto.flow_control_mode == 'FLOW_CONTROL_MODE_FAST'
                          ? '优先跑量'
                          : adDto.flow_control_mode == 'FLOW_CONTROL_MODE_BALANCE'
                          ? '均衡投放'
                          : adDto.flow_control_mode == 'FLOW_CONTROL_MODE_SMOOTH'
                          ? '控制成本'
                          : ''
                      }}</span>
                    </div>
                    <div class="settingBox">
                      <span class="s_label">投放时间:</span>
                      <span class="s_text">{{
                        (adDto.schedule_type == 'SCHEDULE_FROM_NOW' ? '长期投放' : adDto.start_time,
                        adDto.end_time)
                      }}</span>
                    </div>
                    <div class="settingBox">
                      <span class="s_label">投放时段:</span>
                      <span class="s_text">{{ adDto.times }}</span>
                    </div>
                    <div class="settingBox">
                      <span class="s_label">定向包:</span>
                      <span class="s_text">{{ adDto.dPackagename }}</span>
                    </div>
                  </div>
                </div>
              </div></el-col
            >
            <!-- 转化/落地页模块开始 -->
            <el-col :span="6"
              ><div class="grid-content ep-bg-purple" />
              <!-- 转化/落地页模块开始 -->
              <div class="toutiaoAddModel-carditem">
                <div class="defineHead">
                  <span>转化/落地页 </span>
                  <span @click="adWebFun"
                    ><i title="编辑" class="cursor editIcon el-icon-edit disabled"></i
                  ></span>
                </div>
                <div class="defineBody">
                  <div class="mandatory">
                    <div class="mandatorybox" v-for="item in OptTargetDtos">
                      <div class="settingBox">
                        <span class="s_label">子账户:</span>
                        <span class="s_text">{{ item.accountId }}</span>
                      </div>
                      <div class="settingBox">
                        <span class="s_label">转化/落地页:</span>
                        <span class="s_text">{{ item.landPageId }}</span>
                      </div>
                      <div class="settingBox">
                        <span class="s_label">优化目标:</span>
                        <span class="s_text">{{ item.targetedName }}</span>
                      </div>
                    </div>
                    <div class="mandatorybox" v-for="item in eventDtos">
                      <!-- <span class="tHead s_label">账号:{{ accountId }}</span> -->
                      <div class="settingBox">
                        <span class="s_label">子账户:</span>
                        <span class="s_text">{{ item.accountId }}</span>
                      </div>
                      <div v-if="adDto.ms == 0">
                        <div class="settingBox">
                          <span class="s_label">推广内容:</span>
                          <span class="s_text">{{ item.asset_idsName }}</span>
                        </div>
                        <div class="settingBox">
                          <span class="s_label">事件管理优化目标:</span>
                          <span class="s_text">{{ item.external_actionName }}</span>
                        </div>
                        <div class="settingBox">
                          <span class="s_label">检测链接:</span>
                          <span class="s_text">{{ item.action_track_url }}</span>
                        </div>
                      </div>

                      <div v-if="adDto.ms == 1">
                        <div class="settingBox">
                          <span class="s_label">事件名称:</span>
                          <span class="s_text">{{ item.checkEvent }}</span>
                        </div>
                        <div class="settingBox">
                          <span class="s_label">电商优化目标:</span>
                          <span class="s_text">{{ item.checkCommodityName }}</span>
                        </div>
                        <div class="settingBox">
                          <span class="s_label">直达链接:</span>
                          <span class="s_text">{{ item.open_url }}</span>
                        </div>
                        <div class="settingBox">
                          <span class="s_label">落地页链接:</span>
                          <span class="s_text">{{ item.external_url }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div></el-col
            >
            <!-- 创意配置 -->
            <el-col :span="6"
              ><div class="grid-content ep-bg-purple" />
              <!-- 创意配置模块开始 -->
              <div class="toutiaoAddModel-carditem">
                <div class="defineHead">
                  <span>创意配置 </span>
                  <span @click="LdeasSetFun"
                    ><i title="编辑" class="cursor editIcon el-icon-edit disabled"></i
                  ></span>
                </div>
                <div class="defineBody">
                  <div class="mandatory">
                    <!-- <div class="settingBox">
                      <span class="s_label">创意方式:</span>
                      <span class="s_text">{{creativeDto}}</span>
                    </div> -->
                    <div class="settingBox">
                      <span class="s_label">标题组:</span>
                      <span class="s_text">{{ creativeDto.id }}</span>
                    </div>
                    <!-- <div class="settingBox">
                      <span class="s_label">视频类别:</span>
                      <span class="s_text">{{creativeDto.}}</span>
                    </div> -->

                    <div class="settingBox">
                      <span class="s_label">来源:</span>
                      <span class="s_text">{{ creativeDto.source }}</span>
                    </div>
                  </div>
                </div>
              </div></el-col
            >
          </el-row>
        </div>
        <!-- 按钮 -->
        <div class="toutiaoAddModelBtn" @click="createPlanFun">
          <button type="button" class="el-button el-button--default el-button--small">
            <!----><!----><span> 保存并去发布 </span>
          </button>
        </div>
      </el-card>
    </div>
    <!-- 广告组配置抽屉 color: #1d2548;font-size: 20px;font-weight: 700;-->
    <el-drawer size="60%" v-model="adsSetOn" :direction="direction" title="广告组配置">
      <toutiaoAdsSetDrawer @campaignDto="campaignDtoFun"></toutiaoAdsSetDrawer
    ></el-drawer>
    <!-- 广告计划配置 -->
    <el-drawer size="60%" v-model="AdPlanOn" :direction="direction" title="广告计划配置">
      <toutiaoAdPlanDrawer
        :toutiaoMedia="toutiaoMedia"
        :optionsData="optionsData"
        @updateOptions="updateOptions"
        @getPlanData="getPlanData"
      ></toutiaoAdPlanDrawer
    ></el-drawer>

    <!-- 转换/落地页配置 -->
    <el-drawer size="90%" v-model="adWebOn" :direction="direction" title="转换/落地页配置">
      <toutiaoAdWebDrawer
        :data="webTableData"
        :type="adDto.sjflag"
        :msType="adDto.ms"
        @getOptTargetDtos="getOptTargetDtos"
        @getEventDtos="getEventDtos"
      ></toutiaoAdWebDrawer>
    </el-drawer>
    <!-- 创意配置 -->
    <el-drawer size="60%" v-model="LdeasSetOn" :direction="direction" title="创意配置">
      <toutiaLdeasSet
        :selectGroupTitleArr="selectGroupTitleArr"
        :selectIndustryArr="selectIndustryArr"
        @flushTitleFun="flushTitleFun"
        @creativeDtoFun="creativeDtoFun"
        :SonAccountData="SonAccountData"
        :SonAccountModelData="SonAccountModelData"
        @getSonAccountFun="getSonAccountFun"
        @productOkFun="productOkFun"
        :dialogFormVisible="dialogFormVisible"
      ></toutiaLdeasSet
    ></el-drawer>
  </div>
</template>

<script>
import selectSonAccount from '@/components/batch/selectSonAccount.vue'
import { ElMessage } from 'element-plus'
import { inject, reactive, toRefs } from 'vue'
import toutiaoAdsSetDrawer from '../toutiaoComponents/toutiaoAdsSetDrawer.vue'
import toutiaoAdPlanDrawer from '../toutiaoComponents/toutiaoAdPlanDrawer.vue'
import toutiaoAdWebDrawer from '../toutiaoComponents/toutiaoAdWebDrawer.vue'
import toutiaLdeasSet from '../toutiaoComponents/toutiaLdeasSet.vue'
import {
  selectGroupTitle,
  selectIndustry,
  getCard,
  selectToutiaoMedia,
  selectDirectionalNotPage,
  createPlan,
  createCard,
} from '@/request/new/videoBatchManagement'
export default {
  components: {
    toutiaoAdsSetDrawer,
    toutiaoAdPlanDrawer,
    toutiaoAdWebDrawer,
    selectSonAccount,
    toutiaLdeasSet,
  },

  name: 'toutiaoAddModel',
  setup() {
    const state = reactive({
      // 控制子组件新增弹窗
      dialogFormVisible: false,
      // 请求获取的首选媒体数据（广告计划-头条）
      toutiaoMedia: [],
      creativeDto: '',
      campaignDto: '',
      // 广告计划配置的数据
      adDto: '',
      // 标题组数据
      selectGroupTitleArr: [],
      // 请求获取定向包数据
      optionsData: [],
      // 请求行业列表（创意分类）
      selectIndustryArr: [],
      // 传入选择子账户组件中说明是什么类型
      selectSonAccountData: {
        type: 'toutiao',
      },
      // 选中的子账户数据
      SonAccountData: [],
      // 转化、落地页配置
      webTableData: [],
      // 选中的转化、落地页数据 -->转化追踪
      OptTargetDtos: [],
      // 选中的转化、落地页数据 -->事件管理
      eventDtos: [],
      // 选中的子账户创意组件数据
      SonAccountModelData: [],
      // 提交新增卡片
      productOkFun: (val) => {
        createCard(val).then((res) => {
          ElMessage({
            type: 'success',
            message: '创建卡片成功,手动关闭弹窗',
          })
        })
      },
      // 接收选择子账户组件的值
      getSelectSonAccountData: (val) => {
        state.SonAccountData = val
        state.SonAccountData.forEach((item) => {
          state.webTableData.push({
            accountName: item,
            webData: [],
            checked: '',
            // 选中的短剧优化目标
            targeted: '',
            // 选中的短剧优化目标名称
            targetedName: '',
            targetData: [],
            // 事件管理的参数
            landPageId: '',
            asset_ids: [],
            asset_idsName: '',
            external_action: '',
            external_actionName: '',
            action_track_url: '',
            // 资产数据id
            assetData: [],
            // 资产下拉框
            assetDataList: [],
            // 直达链接
            open_url: '',
            // 事件名称的数据
            eventNameDate: [],
            // 选中的事件名称
            checkEvent: '',
            // 电商优化目标的数据
            commodityDate: [],
            // 选中的电商优化目标
            checkCommodity: '',
            // 选中的电商优化目标名称
            checkCommodityName: '',
          })
        })
      },
      // 重置
      ResetPageFun: () => {
        location.reload()
      },
      // 刷新定向包列表
      updateOptions: () => {
        selectDirectionalNotPageFun()
      },
      // 接收广告计划配置
      getPlanData: (val) => {
        state.AdPlanOn = false
        state.adDto = val
      },
      // 广告组抽屉开关
      adsSetOn: false,
      AdPlanOn: false,
      // 转换/落地页抽屉开关
      adWebOn: false,
      LdeasSetOn: false,
      // 广告组配置
      adsSetFun: () => {
        state.adsSetOn = true
      },
      // 广告计划配置
      AdPlanFun: () => {
        state.AdPlanOn = true
      },
      // 转换/落地页配置
      adWebFun: () => {
        if (state.SonAccountData.length > 0) {
          // state.adWebOn = true
          state.adWebOn ? (state.adWebOn = false) : (state.adWebOn = true)
        } else {
          ElMessage({
            type: 'error',
            message: '请选择子账户',
          })
        }
      },
      // 接收转化、落地页配置数据 -->转化追踪
      getOptTargetDtos: (val) => {
        state.adWebFun()
        // 展示的转化/落地页
        state.OptTargetDtos = val
        state.adWebOn = false
      },
      // 接收转化、落地页配置数据 --> 电商或短剧(事件管理)
      getEventDtos: (val) => {
        state.adWebFun()
        // 展示的转化/落地页
        state.eventDtos = val
        state.adWebOn = false
      },

      // 创意配置
      LdeasSetFun: async () => {
        // 接口请求必须带上await
        if (state.SonAccountData.length < 1) {
          ElMessage({
            type: 'error',
            message: '请选择子账户',
          })
          return
        }
        // 请求标题组数据
        await selectGroupTitle().then((res) => {
          state.selectGroupTitleArr = res.data
        })
        // 请求行业列表（创意分类）
        await selectIndustry().then((res) => {
          state.selectIndustryArr = res.data
        })
        // 必须在全部请求最后 打开抽屉
        state.LdeasSetOn = true
      },
      // 刷新标题组接口
      flushTitleFun: () => {
        selectGroupTitle().then((res) => {
          state.selectGroupTitleArr = res.data
        })
      },
      // 接收创意配置数据
      creativeDtoFun: (val) => {
        state.creativeDto = val
        state.LdeasSetOn = false
      },
      // 接收广告组配置数据
      campaignDtoFun: (val) => {
        state.campaignDto = val
        state.adsSetOn = false
        console.log('接收广告组配置数据', state.campaignDto)
      },
      // 接收创意组件的子账户id
      getSonAccountFun: (val) => {
        const datas = {
          id: val,
        }

        getCard(datas).then((res) => {
          state.SonAccountModelData = res.data
          state.SonAccountModelData.forEach((item) => {
            item.SonAccountId = val
          })
        })
      },
      // 保存并去发布
      createPlanFun: () => {
        // console.log('state.campaignDto', state.campaignDto)
        // console.log('state.adDto', state.adDto)
        // console.log('state.creativeDto', state.creativeDto)
        // console.log('state.SonAccountData', state.SonAccountData)
        // console.log('state.OptTargetDtos', state.OptTargetDtos)
        // console.log('state.eventDtos', state.eventDtos)
        // console.log('campaignDto.modelName', state.campaignDto.modelName)
        if (
          state.campaignDto == '' ||
          state.adDto == '' ||
          state.creativeDto == '' ||
          state.SonAccountData == '' ||
          (state.OptTargetDtos == '' && state.eventDtos == '') ||
          state.campaignDto.modelName == ''
        ) {
          ElMessage({
            type: 'error',
            message: '请填写完必填数据',
          })
          return
        }
        const data = {
          campaignDto: state.campaignDto,
          adDto: state.adDto,
          creativeDto: state.creativeDto,
          accountIds: state.SonAccountData,
          modelName: state.campaignDto.modelName,
        }

        if (data.adDto.sjflag == 0) {
          data.adDto.optTargetDtos = state.OptTargetDtos
        } else {
          data.adDto.eventDtos = state.eventDtos
        }

        createPlan(data).then((res) => {
          ElMessage({
            type: 'success',
            message: '创建成功',
          })
          // location.reload()
        })
      },
    })
    // 获取首选媒体数据（广告计划-头条
    const selectToutiaoMediaFun = () => {
      selectToutiaoMedia().then((res) => {
        console.log('获取首选媒体数据（广告计划', res.data)
        state.toutiaoMedia = res.data
      })
    }

    selectToutiaoMediaFun()

    // 获取定向包查询2数据
    const selectDirectionalNotPageFun = () => {
      selectDirectionalNotPage().then((res) => {
        console.log('获取定向包查询2数据', res.data)
        state.optionsData = res.data
      })
    }

    selectDirectionalNotPageFun()

    return {
      ...toRefs(state),
      selectDirectionalNotPageFun,
    }
  },
}
</script>
<style lang="scss" scoped>
.toutiaoAddModelcontent {
  margin-top: 20px;
}
.toutiaoAddModel-carditem {
  width: 100%;
  font-size: 14px;
  border: 1px solid #e2e6f0;
  border-radius: 4px;
  margin-top: 0 !important;
  margin-right: 20px;
}
.defineHead {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f6f7fa;
  border-radius: 3px 3px 0 0;
  color: #282c33;
  padding: 12px 16px;
  box-sizing: border-box;
}
.defineBody {
  width: 100%;
  height: 400px;
  padding: 6px 12px;
  overflow: auto;
  box-sizing: border-box;
}
.mandatory {
  height: 338px;
  max-height: 338px;
}
.settingBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  .s_label {
    width: max-content;
  }
  .s_text {
    max-width: 60%;
    word-break: break-all;
  }
}
.mandatorybox {
  margin-top: 10px;
}
.tHead {
  font-size: 16px;
  font-weight: 600;
}
.toutiaoAddModelBtn {
  padding-top: 20px;
  text-align: right;
}

.toutiaoAddModelTitleTop {
  > div {
    margin-right: 10px;
  }
}
</style>
