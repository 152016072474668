<template>
  <!-- 头条创意配置抽屉内容 -->
  <div class="toutiaLdeasSet paddbox">
    <el-card>
      <div class="toutiaLdeasSetitem">
        <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="120px">
          <el-form-item label="标题组" prop="id">
            <el-select v-model="ruleForm.id" class="m-2" placeholder="请选择标题组" size="large">
              <el-option
                v-for="item in selectGroupTitleArr"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
            <div class="cursor ldTitle" @click="openNewWindows">
              新增标题
            </div>
            <div class="cursor ldTitle" @click="flushTitleFun">
              刷新标题
            </div>
          </el-form-item>
          <el-form-item label="创意组件">
            <el-button @click="toutiaLdeasSetFun">添加</el-button
            ><span>已选{{ component_materials.length }}</span>
          </el-form-item>
          <el-form-item label="创意分类">
            <el-cascader
              :show-all-levels="false"
              v-model="third_industry_id"
              :options="selectIndustryArr"
              :props="{
                value: 'id',
                label: 'label',
              }"
              @change="handleChange"
            />
          </el-form-item>
          <el-form-item label="创意标签">
            <el-input
              placeholder="最多20个,用空格隔开"
              @keyup.enter.native="ldTableEnterFun"
              v-model="ad_keywords"
            >
              <template #append>添加（回车键）</template>
            </el-input>
            <div class="cybox">
              <div class="adListTitle el-row">
                <div class="">已选</div>
                <div class="">
                  <button
                    type="button"
                    class="el-button el-button--text el-button--small"
                    @click="ldTabAllDeleteFun"
                  >
                    <span> 清除 </span>
                  </button>
                </div>
              </div>
              <div class="flex es">
                <div class="adSy el-row" v-for="(item, index) in ldTablearr">
                  <div class="">
                    <span>{{ item }}</span>
                  </div>
                  <div class="">
                    <button
                      type="button"
                      class="el-button el-button--text el-button--small"
                      @click="deleteLdTabFun(index)"
                    >
                      <span> × </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="来源" prop="source">
            <el-input v-model="ruleForm.source"></el-input>
          </el-form-item>
          <div class="formbtn">
            <el-button type="primary" @click="submitForm(ruleFormRef)">确认</el-button>
          </div>
        </el-form>
      </div>
    </el-card>
    <el-drawer size="50%" v-model="toutiaLdeasSetOn" :direction="direction" title="选择创意组件">
      <div>
        <el-button type="primary" @click="dialogFormVisible = true">新增创意组件</el-button>
      </div>
      <div class="paddbox">
        <div class="flex">
          <div class="cyzj-left">
            <el-tooltip
              v-for="item in SonAccountData"
              effect="dark"
              :content="item"
              placement="left-start"
            >
              <el-button class="list_item" @click="checkedSonAccount(item)">{{ item }}</el-button>
            </el-tooltip>
          </div>
          <div class="cyzj-right">
            <el-card>
              <el-table
                ref="multipleTableRef"
                height="550"
                :data="SonAccountModelData"
                :row-key="(row) => row.component_id"
                style="width: 100%"
                :header-cell-class-name="cellClass"
                @select="serChoose"
              >
                <el-table-column
                  type="selection"
                  width="55"
                  :reserve-selection="true"
                ></el-table-column>
                <el-table-column property="component_name" label="组件名称" width="120">
                  <template #default="scope">{{ scope.row.component_name }}</template>
                </el-table-column>

                <el-table-column property="component_id" label="组件ID" width="120" />
                <el-table-column property="button_text" label="按钮标题" show-overflow-tooltip />
              </el-table>
            </el-card>
          </div>
        </div>
        <div class="formbtn cyzjss">
          <el-button type="primary" @click="submitForm2(ruleFormRef)">确认</el-button>
        </div>
      </div>
      <el-dialog v-model="dialogFormVisible" title="添加创意组件">
        <el-form :model="ruleForm" :rules="rules">
          <el-form-item label="选择账户" :label-width="formLabelWidth" prop="accountIds">
            <el-select v-model="ruleForm.accountIds" placeholder="请选择" multiple>
              <el-option v-for="item in SonAccountData" :label="item" :value="item" />
            </el-select>
          </el-form-item>
          <el-form-item label="组件名称" prop="component_name" :label-width="formLabelWidth">
            <el-input
              v-model="ruleForm.component_name"
              autocomplete="off"
              maxlength="10"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="卡片图片" :label-width="formLabelWidth">
            <!-- action="https://wxappvue.df01.top/api/toutiaoGroupTitle/uploadCardImage" -->
            <el-upload
              class="avatar-uploader"
              :headers="{ authorize: token }"
              action="https://wxappvue.df01.top/api/toutiaoGroupTitle/uploadCardImage"
              :data="imageUrl"
              :on-success="onSuccess"
              multiple
              :show-file-list="false"
              :limit="50"
            >
              <img v-if="imageUrl" :src="imageUrl" class="imageUrl" />
              <el-button v-else size="small" type="success" round>上传图片</el-button>
            </el-upload>
            <!-- <el-input v-model="imageUrl" autocomplete="off" maxlength="20" show-word-limit /> -->
          </el-form-item>
          <el-form-item label="卡片标题" prop="title" :label-width="formLabelWidth">
            <el-input v-model="ruleForm.title" autocomplete="off" maxlength="20" show-word-limit />
          </el-form-item>
          <el-form-item label="推广卖点" :label-width="formLabelWidth">
            <div>已选{{ product_selling_pointsArr.length }}/10</div>

            <div v-for="(item, index) in product_selling_pointsArr" style="margin-top:6px">
              <el-input
                style="width:380px"
                placeholder="最小6个字符，最大9个字符"
                v-model="item.t"
                autocomplete="off"
                minlength="6"
                maxlength="9"
                show-word-limit
              />
              <el-button
                type="primary"
                style="margin-left:20px"
                @click="product_selling_pointsAddFun"
                size="mini"
                text
                >新增</el-button
              >
              <el-button
                type="plain"
                @click="DeleProduct_selling_pointsFun(index)"
                style="margin-left:20px"
                size="mini"
                text
                v-if="index > 0"
                >删除</el-button
              >
            </div>
          </el-form-item>
          <el-form-item label="行动号召" :label-width="formLabelWidth" prop="button_text">
            <el-input
              v-model="ruleForm.button_text"
              autocomplete="off"
              placeholder="最小2个字符，最大6个字符"
              minlength="2"
              maxlength="6"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="智能优选" :label-width="formLabelWidth">
            <el-switch v-model="enable_personal_action" :active-value="1" :inactive-value="0" />
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取消</el-button>
            <el-button type="primary" @click="productOkFun">确定</el-button>
          </span>
        </template>
      </el-dialog>
    </el-drawer>
  </div>
</template>

<script>
import { reactive, toRefs, ref, inject, watch } from 'vue'
import { ElMessage } from 'element-plus'
export default {
  name: 'toutiaLdeasSet',
  components: {},
  props: ['selectGroupTitleArr', 'selectIndustryArr', 'SonAccountData', 'SonAccountModelData'],
  setup(props, context) {
    const parent = { ...context }
    // const multipleTableRef = ref(null)
    const state = reactive({
      product_selling_points: [],
      product_selling_pointsArr: [{ t: '' }],
      // 获取用户token
      token: 'Bearer' + localStorage.getItem('token'),
      imageUrl: '',
      enable_personal_action: 0,
      dialogFormVisible: false,
      // cascaderProps: ,
      SonAccountData: props.SonAccountData,
      selectGroupTitleArr: props.selectGroupTitleArr,
      selectIndustryArr: props.selectIndustryArr,
      SonAccountModelData: props.SonAccountModelData,
      multipleTableRef: '',
      // 创意分类
      third_industry_id: '',
      tableData: [],
      // 表格选择的 创意组件id
      component_materials: [],
      toutiaLdeasSetOn: false,
      ruleFormRef: '',
      ad_keywords: '',
      ldTablearr: [],
      options: [],
      ruleForm: {
        source: '',
        id: '',
        component_name: '',
        title: '',
        accountIds: [],
        button_text: '',
      },
      // 必填规则
      rules: {
        source: [{ required: true, message: '请输入来源', trigger: 'blur' }],
        id: [{ required: true, message: '请选择标题组', trigger: 'change' }],
        accountIds: [{ required: true, message: '请选择账户', trigger: 'change' }],
        title: [{ required: true, message: '请输入卡片标题', trigger: 'blur' }],
        component_name: [
          {
            required: true,
            message: '请输入组件名称',
            trigger: 'blur',
            max: 10,
          },
        ],
        button_text: [
          {
            required: true,
            message: '行动号召最少不能低于2个字',
            trigger: 'blur',
            min: 2,
          },
        ],
      },
      // 确定提交创建卡片
      productOkFun: () => {
        if (
          state.product_selling_pointsArr.some(function checknumber(item) {
            return item.t.length < 6
          })
        ) {
          ElMessage({
            type: 'error',
            message: '推广卖点不能少于6个字符',
          })
          return
        }

        state.product_selling_pointsArr.forEach((item) => {
          state.product_selling_points.push(item.t)
        })
        const data = {
          accountIds: state.ruleForm.accountIds,
          component_name: state.ruleForm.component_name,
          title: state.ruleForm.title,
          button_text: state.ruleForm.button_text,
          imageUrl: state.imageUrl,
          enable_personal_action: state.enable_personal_action,
          product_selling_points: state.product_selling_points,
        }

        parent.emit('productOkFun', data)
      },
      // 删除推广卖点
      DeleProduct_selling_pointsFun: (index) => {
        state.product_selling_pointsArr.splice(index, 1)
      },
      // 新增推广卖点
      product_selling_pointsAddFun: () => {
        if (state.product_selling_pointsArr.length < 10) {
          state.product_selling_pointsArr.push({ t: '' })
        } else {
          ElMessage({
            type: 'error',
            message: '不能超过10个',
          })
        }
      },
      // 上传图片成功
      onSuccess: (response, uploadFile) => {
        // selectAllPictureFun()
        state.imageUrl = response.data.data
      },

      // 确定
      submitForm: async (formEl) => {
        if (!formEl) {
          console.log(formEl)
          return
        }
        await formEl.validate((valid, fields) => {
          if (valid) {
            let component = 0

            if (state.third_industry_id && state.third_industry_id.length > 0) {
              component = state.third_industry_id[state.third_industry_id.length - 1]
            }
            console.log(state.third_industry_id)
            const creativeDto = {
              id: state.ruleForm.id,
              component_materials: state.component_materials,
              third_industry_id: component,
              ad_keywords: state.ldTablearr,
              source: state.ruleForm.source,
            }

            parent.emit('creativeDtoFun', creativeDto)
          } else {
            console.log('error submit!', fields)
          }
        })
      },
      // 小确定
      submitForm2: () => {
        state.toutiaLdeasSetOn = false
      },
      // 监听回车 新增创意标签
      ldTableEnterFun: () => {
        if (state.ldTablearr.length + state.ad_keywords.trim().split(/\s+/).length > 20) {
          ElMessage({
            type: 'error',
            message: '不能超过二十个',
          })
          return
        }

        if (state.ad_keywords != '' && state.ad_keywords.trim() != '') {
          state.ldTablearr = state.ldTablearr.concat(state.ad_keywords.trim().split(/\s+/))
          state.ad_keywords = ''
        }
      },
      // 删除创意标签
      deleteLdTabFun: (index) => {
        state.ldTablearr.splice(index, 1)
      },
      // 清除全部创意标签
      ldTabAllDeleteFun: () => {
        state.ldTablearr = []
      },
      // 选择创意组件按钮
      toutiaLdeasSetFun: () => {
        state.toutiaLdeasSetOn = true
      },
      // 选择创意组件 表格选完后
      serChoose(selection, row) {
        // clearSelection：用于多选表格，清空用户的选择
        // 此时全部的checkbox处于未勾选，当用户第一次点击的时候，selection为一个数组，里面存放的为当前这一行的数据
        // 这这里将这行的状态又变为了勾选
        // 缓存用于记录
        const newArr = []

        state.component_materials = selection
        state.component_materials.forEach((item, index) => {
          // 检查缓存中是否已经存在SonAccountId

          if (newArr.find((c) => c.SonAccountId === item.SonAccountId)) {
            newArr.find((c, i) => {
              // 如果存在就找到把 之前的删掉，保留当前
              if (c.SonAccountId === item.SonAccountId) {
                newArr.splice(i, 1)
                newArr.push(item)
              }
            })
            return
          }
          // 不存在就说明以前没遇到过，把它记录下来
          newArr.push(item)
        })

        state.multipleTableRef.clearSelection()
        state.component_materials = newArr
        // 更新选中的数据
        newArr.forEach((rows) => {
          state.multipleTableRef.toggleRowSelection(rows, true)
        })
        // 用于多选表格，切换某一行的选中状态，若是使用了第二个参数，则是设置这一行选中与否（selected 为 true 则选中）
      },
      // 关闭表头的全选
      cellClass: (row) => {
        if (row.columnIndex === 0) {
          return 'disabledCheck'
        }
      },
      // 刷新标题组
      flushTitleFun: () => {
        parent.emit('flushTitleFun', 'ok')
      },
      // 点击子账户请求创意组件数据
      checkedSonAccount: (val) => {
        parent.emit('getSonAccountFun', val)
      },
      // 新增标题(打开新页面)
      openNewWindows: () => {
        window.open('titleGroup') // 打开标题组页面
      },
    })

    return {
      ...toRefs(state),
    }
  },
}
</script>
<style lang="scss" scoped>
.imageUrl {
  width: 120px;
  height: 120px;
}
.formbtn {
  text-align: right;
  margin-right: 50px;
}
.cyzjss {
  margin-top: 40px;
}
.cybox {
  display: inline-block;
  width: 520px;
  height: 200px;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 5px;
  overflow: auto;
  margin-top: 10px;
}
.adListTitle {
  justify-content: space-between;
  height: 40px;
  background-color: rgb(226, 224, 224);
  padding-left: 20px;
  line-height: 40px;
  padding-right: 20px;
  box-sizing: border-box;
}
.adSy {
  display: flex;
  padding: 0 20px;
  box-sizing: border-box;
  justify-content: space-between;
  width: 220px;
  height: 30px;
  background-color: rgb(238, 231, 231);
  margin: 14px;
  border-radius: 5px;
  line-height: 30px;
}
.es {
  flex-wrap: wrap;
}
.cyzj-left {
  width: 19%;
  height: 600px;
  overflow: auto;
  margin-right: 20px;
  background-color: #fff;
  box-shadow: 0 6px 20px #f1f4f9;
  border-radius: 10px;
  flex-direction: column;
}
.cyzj-right {
  flex: 1;
}
.list_item {
  font-size: 14px;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  margin: 0;
}
.ldTitle {
  display: inline-block;
  margin-left: 20px;
  cursor: pointer;
  color: #409eff;
}
:deep(.el-table .disabledCheck .cell .el-checkbox__inner) {
  display: none;
}
</style>
