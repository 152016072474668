<template>
  <!-- 头条广告组计划抽屉内容 -->
  <div class="toutiaoAdPlanDrawer paddbox">
    <el-card>
      <div class="toutiaoAdPlanDraweritem">
        <el-form ref="ruleFormRef" label-width="120px">
          <el-form-item label="计划名称" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="投放模式">
            <el-radio-group v-model="radio7" size="large" :disabled="ableValue">
              <el-radio-button :label="0">短剧</el-radio-button>
              <el-radio-button :label="1" @click="radio1 = 1"
                >电商</el-radio-button
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label="数据对接方式">
            <el-radio-group v-model="radio1" size="large" :disabled="ableValue">
              <el-radio-button :label="0" :disabled="radio7 == 1"
                >转化追踪</el-radio-button
              >
              <el-radio-button :label="1">事件管理</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="广告位置">
            <el-radio-group v-model="radio2" size="large">
              <el-radio-button :label="'0'" disabled
                >系统优选广告位</el-radio-button
              >
              <el-radio-button :label="'MANUAL'">首选媒体</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <!-- 选了媒体后显示 -->
          <div class="firstMedia" v-if="radio2 == 'MANUAL'">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                :label="item.value"
                style="margin-bottom:10px"
                v-for="(item, index) in toutiaoMedia"
                >{{ item.text }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
          <el-form-item label="投放场景">
            <el-radio-group v-model="radio3" size="large">
              <el-radio-button :label="'SMART_BID_CUSTOM'">
                <span style="font-size:16px">常规投放</span>
                <div class="r_desc">
                  控制成本，尽量消耗完预算
                </div>
              </el-radio-button>
              <el-radio-button :label="'SMART_BID_CONSERVATIVE'">
                <span style="font-size:16px">放量投放</span>
                <div class="r_desc">
                  接受成本上浮，尽量消耗更多预算
                </div></el-radio-button
              >
              <el-radio-button :label="'SMART_BID_NO_BID'">
                <span style="font-size:16px">最大转化投放</span>
                <div class="r_desc">
                  匀速花完预算，获取更多转化
                </div></el-radio-button
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label="竞价策略">
            <el-radio-group v-model="radio4" size="large">
              <el-radio-button :label="'FLOW_CONTROL_MODE_FAST'"
                >优先跑量</el-radio-button
              >
              <el-radio-button :label="'FLOW_CONTROL_MODE_BALANCE'"
                >均衡投放</el-radio-button
              >
              <el-radio-button :label="'FLOW_CONTROL_MODE_SMOOTH'"
                >控制成本上限</el-radio-button
              >
            </el-radio-group>
            <!-- 不等于’控制成本上限‘ 的时候显示 否则-->
            <div v-if="radio4 != 'FLOW_CONTROL_MODE_SMOOTH'">
              适用场景：预算基本花不完，跑量较困难，希望成本相对稳定
            </div>
            <div v-else>适用场景：预算偏小或无跑量问题，成本越低越好</div>
          </el-form-item>
          <!-- 投放场景不是最大转化投放 的时候显示 -->
          <el-form-item
            label="日预算"
            prop="form1"
            v-if="radio3 != 'SMART_BID_NO_BID'"
          >
            <el-input v-model="ruleForm.budget" type="number"></el-input>
          </el-form-item>
          <el-form-item label="投放时间">
            <el-radio-group v-model="radio5" size="large">
              <el-radio-button :label="'SCHEDULE_FROM_NOW'"
                >从今天起长期投放</el-radio-button
              >
              <el-radio-button :label="'SCHEDULE_START_END'"
                >设置开始和结束日期</el-radio-button
              >
            </el-radio-group>
          </el-form-item>
          <!-- 不是从今天起长期投放的时候显示 -->
          <div class="firstMedia" v-if="radio5 != 'SCHEDULE_FROM_NOW'">
            <el-date-picker
              v-model="value1"
              :default-value="new Date()"
              type="datetimerange"
              format="YYYY-MM-DD HH:mm:ss"
              value-format="YYYY-MM-DD HH:mm:ss"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :size="size"
            />
          </div>
          <el-form-item label="投放时段">
            <el-radio-group v-model="launchTime" size="large">
              <el-radio-button :label="0">不限</el-radio-button>
              <el-radio-button :label="1">指定时间段</el-radio-button>
              <div v-if="launchTime == 1">
                <weektime
                  v-model="mult_timeRange"
                  :data="weektimeData"
                  @on-clear="clearWeektime"
                ></weektime>
              </div>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="付费方式">
            <el-radio-group v-model="radio6" size="large">
              <el-radio-button :label="'PRICING_OCPM'"
                >按展示付费（OCPM）</el-radio-button
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label="目标转化价" prop="form1">
            <el-input v-model="ruleForm.cpa_bid" type="number"></el-input>
          </el-form-item>

          <!-- <el-form-item label="搜索快投关键词">
            <el-radio-group v-model="audience_extend" size="large">
              <el-radio-button :label="'OFF'">不启用</el-radio-button>
              <el-radio-button :label="'ON'">启用</el-radio-button>
            </el-radio-group>
          </el-form-item> -->

          <el-form-item label="继承优质计划">
            <el-radio-group v-model="auto_inherit_switch" size="large">
              <el-radio-button :label="'OFF'">不启用</el-radio-button>
              <el-radio-button :label="'ON'">启用</el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-form-item
            label="被继承计划归属"
            v-if="auto_inherit_switch == 'ON'"
          >
            <el-radio-group v-model="inherit_type" size="large">
              <el-radio-button :label="'INHERIT_FROM_ACCOUNT'"
                >当前账户</el-radio-button
              >
              <el-radio-button :label="'INHERIT_FROM_CUSTOMER'"
                >组织账户</el-radio-button
              >
            </el-radio-group>
          </el-form-item>

          <el-form-item
            label="账户"
            v-if="
              inherit_type == 'INHERIT_FROM_CUSTOMER' &&
                auto_inherit_switch == 'ON'
            "
          >
            <el-input
              placeholder="最多3个,用空格隔开"
              @keyup.enter.native="ldTableEnterFun"
              v-model="ad_keywords"
            >
              <template #append>添加（回车键）</template>
            </el-input>
            <div class="cybox">
              <div class="adListTitle el-row">
                <div class="">已选</div>
                <div class="">
                  <button
                    type="button"
                    class="el-button el-button--text el-button--small"
                    @click="ldTabAllDeleteFun"
                  >
                    <span> 清除 </span>
                  </button>
                </div>
              </div>
              <div class="flex es">
                <div class="adSy el-row" v-for="(item, index) in ldTablearr">
                  <div class="">
                    <span>{{ item }}</span>
                  </div>
                  <div class="">
                    <button
                      type="button"
                      class="el-button el-button--text el-button--small"
                      @click="deleteLdTabFun(index)"
                    >
                      <span> × </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>

          <el-form-item label="定向选择">
            <el-radio-group v-model="radio7" size="large">
              <el-radio-button :label="0">迪弗定向包</el-radio-button>
              <el-radio-button label="统一日预算" disabled
                >媒体定向包</el-radio-button
              >
            </el-radio-group>
            <div>
              <span
                class="cursor"
                style="color:#0062d9"
                @click="updateOptionsDataFun()"
                ><i class="el-icon-refres"></i>刷新定向包列表
              </span>
              <!-- <el-transfer
                style="margin-top:10px"
                :titles="['可选定向数量', '已选定向数量']"
                v-model="value"
                filterable
                :filter-method="filterMethod"
                filter-placeholder="请输入搜索内容"
                :data="data"
              /> -->
              <div>
                <el-select v-model="dPackageId" placeholder="请选择定向包">
                  <el-option
                    @click="getLable(item)"
                    v-for="item in optionsData"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <span class="cursor" style="color:#0062d9" @click="openTargetFun()"
              ><i class="el-icon-refres"></i>新增定向包
            </span>
          </el-form-item>

          <div class="formbtn">
            <el-button type="primary" @click="submitForm(ruleFormRef)"
              >确认</el-button
            >
          </div>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import weektimeData from '@/components/weektime/weektime_data.js'
import weektime from '@/components/weektime/weektime.vue'
import { reactive, toRefs, ref, inject, computed } from 'vue'
import { ElMessage } from 'element-plus'
export default {
  name: 'toutiaoAdPlanDrawer',
  components: { weektime },
  props: ['data', 'toutiaoMedia', 'optionsData'],
  setup(props, context) {
    const parent = { ...context }

    const mult_timeRange = computed(() => {
      return weektimeData.map((item) => {
        return {
          id: item.row,
          week: item.value,
          value: splicing(item.child),
        }
      })
    })

    const splicing = (list) => {
      let same
      let i = -1
      const len = list.length
      const arr = []

      if (!len) {
        return
      }
      while (++i < len) {
        const item = list[i]

        if (item.check) {
          if (item.check !== Boolean(same)) {
            arr.push(...['、', item.begin, '~', item.end])
          } else if (arr.length) {
            arr.pop()
            arr.push(item.end)
          }
        }
        same = Boolean(item.check)
      }
      arr.shift()
      console.log(arr.shift())

      return arr.join('')
    }

    console.log(props.toutiaoMedia)
    const state = reactive({
      // 接收首选媒体
      toutiaoMedia: props.toutiaoMedia,
      // 接收定向包数据
      optionsData: props.optionsData,
      // 投放时段 0不限 1指定时间端
      launchTime: 0,
      // 自定义投放时间段数据
      weektimeData: weektimeData,
      // 清除投放时段的值
      clearWeektime: () => {
        state.weektimeData.forEach((item) => {
          item.child.forEach((t) => {
            t.check = false
          })
        })
      },

      // ========>
      // 首选媒体选中的值
      checkList: [],
      // 数据对接方式
      radio1: 0, // 0转化转化追踪 1事件管理
      // 数据对接方式的是否可用
      ableValue: false,
      // 广告位置
      radio2: 'MANUAL', // MANUAL 首选媒体
      // 投放场景
      radio3: 'SMART_BID_CUSTOM', // 常规投放 SMART_BID_CUSTOM  放量投放 SMART_BID_CONSERVATIVE  最大转化投放 SMART_BID_NO_BID
      // 竞价策略
      radio4: 'FLOW_CONTROL_MODE_FAST', // FLOW_CONTROL_MODE_FAST优先跑量  FLOW_CONTROL_MODE_BALANCE均衡投放  FLOW_CONTROL_MODE_SMOOTH控制成本
      // 投放时间
      radio5: 'SCHEDULE_FROM_NOW', // SCHEDULE_FROM_NOW从今天起长期投放, SCHEDULE_START_END设置开始和结束日期
      // 付费方式
      radio6: 'PRICING_OCPM',
      // 投放模式
      radio7: 'PRICING_OCPM',
      // 自定义投放时间
      value1: [],
      // 定向包选择
      radio7: 0, // 0短剧 1电商
      // 搜索快投关键词
      audience_extend: 'OFF', //OFF不启用  ON启用
      // 继承优质计划
      auto_inherit_switch: 'OFF', //OFF不启用  ON启用
      // 被继承计划归属
      inherit_type: 'INHERIT_FROM_ACCOUNT', //INHERIT_FROM_ACCOUNT当前账户  INHERIT_FROM_CUSTOMER组织账户

      // 选中的定向包
      dPackageId: '',
      // 选中的定向包名称
      dPackagename: '',
      ruleFormRef: '',
      ruleForm: {
        // 计划名称
        name: '',
        // 日预算
        budget: '',
        // 目标转化价
        cpa_bid: '',
      },
      // 账户=====
      ad_keywords: '',
      ldTablearr: [],
      // end=========

      // 新增定向包
      openTargetFun: () => {
        window.open('Directional')
      },
      // 刷新定向包
      updateOptionsDataFun: () => {
        parent.emit('updateOptions')
      },
      // 获取选中的定向包名称
      getLable: (val) => {
        state.dPackagename = val.name
      },
      // 监听回车 新增账户
      ldTableEnterFun: () => {
        if (
          state.ldTablearr.length +
            state.ad_keywords.trim().split(/\s+/).length >
          3
        ) {
          ElMessage({
            type: 'error',
            message: '不能超过三个',
          })
          return
        }

        if (state.ad_keywords != '' && state.ad_keywords.trim() != '') {
          state.ldTablearr = state.ldTablearr.concat(
            state.ad_keywords.trim().split(/\s+/)
          )
          state.ad_keywords = ''
        }
      },
      // 删除账户
      deleteLdTabFun: (index) => {
        state.ldTablearr.splice(index, 1)
      },
      // 清除全部账户
      ldTabAllDeleteFun: () => {
        state.ldTablearr = []
      },
      // 确定
      submitForm: () => {
        let str = ''

        if (state.ruleForm.name == '') {
          str = '计划名称不能为空'
        }
        if (state.checkList.length == 0) {
          str = '请选择广告位置'
        }
        if (state.ruleForm.budget == '' && state.radio3 != 'SMART_BID_NO_BID') {
          str = '日预算不能为空'
        }
        if (
          (state.ruleForm.budget <= 300 ||
            state.ruleForm.budget > 9999999.99) &&
          state.radio3 != 'SMART_BID_NO_BID'
        ) {
          str = '日预算只能在300元到9999999.99元之间'
        }

        if (state.radio5 == 'SCHEDULE_START_END' && state.value1.length == 0) {
          str = '开始和结束时间不能为空'
        }
        if (state.ruleForm.cpa_bid == '') {
          str = '目标转化价不能为空'
        }
        if (state.dPackageId == '') {
          str = '请选择定向包'
        }
        if (
          state.auto_inherit_switch == 'ON' &&
          state.inherit_type == 'INHERIT_FROM_CUSTOMER' &&
          state.ldTablearr.length == 0
        ) {
          str = '账户不能为空'
        }
        if (str.length > 0) {
          return ElMessage({
            type: 'error',
            message: str,
          })
        } else {
          const PlanData = {
            name: state.ruleForm.name,
            sjflag: state.radio1,
            inventory_catalog: state.radio2,
            inventory_type: state.checkList,
            smart_bid_type: state.radio3,
            flow_control_mode: state.radio4,
            budget: state.ruleForm.budget,
            schedule_type: state.radio5,
            pricing: state.radio6,
            cpa_bid: state.ruleForm.cpa_bid,
            dpackageId: state.dPackageId,
            dPackagename: state.dPackagename,
            ms: state.radio7,
            // audience_extend: state.audience_extend,
            auto_inherit_switch: state.auto_inherit_switch,
          }

          if (state.radio5 == 'SCHEDULE_START_END') {
            PlanData.start_time = state.value1[0]
            PlanData.end_time = state.value1[1]
          }
          if (state.launchTime == 1) {
            PlanData.times = state.weektimeData
          }
          if (state.auto_inherit_switch == 'ON') {
            PlanData.inherit_type = state.inherit_type
          }
          if (
            state.auto_inherit_switch == 'ON' &&
            state.inherit_type == 'INHERIT_FROM_CUSTOMER'
          ) {
            PlanData.inherited_advertiser_id = state.ldTablearr
          }

          console.log('getPlanData', PlanData)
          parent.emit('getPlanData', PlanData)
          state.ableValue = true
        }
      },
    })
    const generateData = () => {
      const data = []
      const states = [
        'California',
        'Illinois',
        'Maryland',
        'Texas',
        'Florida',
        'Colorado',
        'Connecticut ',
      ]
      const initials = ['CA', 'IL', 'MD', 'TX', 'FL', 'CO', 'CT']

      states.forEach((city, index) => {
        data.push({
          label: city,
          key: index,
          initial: initials[index],
        })
      })
      return data
    }

    const data = ref(generateData())
    const value = ref([])

    const filterMethod = (query, item) => {
      return item.initial.toLowerCase().includes(query.toLowerCase())
    }

    return {
      ...toRefs(state),
      data,
      value,
      filterMethod,
      mult_timeRange,
    }
  },
}
</script>
<style lang="scss" scoped>
.formbtn {
  text-align: right;
  margin-right: 50px;
}
.firstMedia {
  width: 70%;
  margin-left: 120px;
  margin-bottom: 30px;
}
.r_desc {
  font-size: 12px;
  margin-top: 6px;
}

.cybox {
  display: inline-block;
  width: 520px;
  height: 200px;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 5px;
  overflow: auto;
  margin-top: 10px;
}

.adListTitle {
  justify-content: space-between;
  height: 40px;
  background-color: rgb(226, 224, 224);
  padding-left: 20px;
  line-height: 40px;
  padding-right: 20px;
  box-sizing: border-box;
}
.adSy {
  display: flex;
  padding: 0 20px;
  box-sizing: border-box;
  justify-content: space-between;
  width: 220px;
  height: 30px;
  background-color: rgb(238, 231, 231);
  margin: 14px;
  border-radius: 5px;
  line-height: 30px;
}
.es {
  flex-wrap: wrap;
}
</style>
